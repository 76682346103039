export enum ERoutes {
	// error-page
	// error_page = 'error-page',
	// access-denied
	access_denied_page = 'access-denied',

	//Inactive subscription
	inactive_subscription = 'inactive-subscription',

	login = '/login',
	login_okta = '/oauth2/login/okta',
	registration = '/registration',
	reset_password = '/reset-password',
	change_password = '/change-password',
	add_new_user = '/add-new-user',
	invitation = '/invitation/:id',
	authorized_user = `/:engId/`,
	//integrations
	integrations_management = 'integrations',
	// users
	users_management = 'users',
	users_management_edit = ':id',
	//subscriptions
	subscriptions_management = 'subscriptions',
	// billings
	billings_management = 'billings',
	// organizations
	organizations_management = 'organizations',
	organizations_management_edit = ':id',
	//engagement
	engagements = 'engagements',
	engagements_archived = 'archived',
	//plans
	plan = 'plan',
	plan_edit = ':planId/plan-document/:storyboardId',
	plan_ai_build = ':planId/ai_build',
	plan_tags_management = 'tags',
	plan_archived = 'archived',
	//Workspaces
	workspaces = 'workspaces',
	workspaces_archived = 'archived',
	workspaces_tags_management = 'tags',
	workspace_build = ':workspaceId/build',
	workspace_ai_build = ':workspaceId/ai-build',
	workspace_edit = ':workspaceId/approach',
	//action_plan
	action_plan = 'action-plan',
	action_plan_archived = 'archived',
	action_plan_edit = ':id',
	action_plan_tags_management = 'tags',
	action_plan_categories_management = 'categories',
	action_plan_manage_integrations = 'manage-integrations',

	//  project_portfolio - projects
	project_portfolio_projects = 'projects',
	project_portfolio_projects_storyboard_details = 'portfolioGuidance/:storyboardId',
	project_portfolio_projects_tags_management = 'tags',
	project_portfolio_projects_categories_management = 'categories',
	project_portfolio_projects_edit_tags_management = 'tags',
	project_portfolio_projects_edit_categories_management = 'categories',
	project_portfolio_projects_edit = ':id',
	project_portfolio_projects_edit_storyboard_details = 'workProducts/:storyboardId',
	// optimization
	optimization = 'optimization',
	optimization_row_data = ':optId/optimization-row-data/:storyboardId',
	optimization_ai_report = ':optId/optimization-ai-report/:storyboardId',
	optimization_ai_build = ':optId/ai-build',
	optimization_cards = ':optId',
	// retrospectives
	retrospectives = 'retrospectives',
	retrospectives_lessons_learned_add = 'new-lessons-learned',
	retrospectives_lessons_learned_edit = 'lessons-learned/:id',
	// actionPlan
	actionPlan_tags_management = 'tags',
	actionPlan_categorys_management = 'categories',
	// storyboards
	storyboards = 'storyboards',
	storyboard_details = ':storyboardId',
	storyboards_tags_management = 'tags',
	storyboards_categories_management = 'categories',
	// home
	home = 'home',
	// diagrams
	diagrams = 'diagrams',
	diagrams_edit = ':diagramId',
	diagrams_ai_build = ':diagramId/ai-build',
	// operating-model
	operating_model = 'operating-model',
	operating_model_archived = 'archived',
	operating_model_ai_build = ':oppModelId/ai-build',
	operating_model_ai_report = ':oppModelId/operating-model-ai-report/:storyboardId',
	// forecast
	forecast = 'forecast',
	forecast_details = ':id',
	forecast_archived = 'archived',
	forecast_tags_management = 'tags',
	forecast_categories_management = 'categories',
	// file-exporter
	file_explorer = 'file-exporter',
	//Industry Reports
	industry_reports = 'industry-reports',
	industry_reports_details = ':storyboardId',
	//process Reports
	process_reports = 'process-reports',
	process_reports_details = ':storyboardId',
	// public pages
	public = 'public',
	public_not_found = 'not-found',
	public_storyboard = 'storyboard/:hash',
	public_optimization_feedback = 'optimization-feedback/:hash',
}

export enum ERoutesFullPath {
	// error-page
	// error_page = 'error-page',
	// access-denied
	access_denied_page = 'access-denied',

	//Inactive subscription
	inactive_subscription = '/inactive-subscription',

	login = '/login',
	login_okta = '/oauth2/login/okta',
	registration = '/registration',
	reset_password = '/reset-password',
	change_password = '/change-password',
	change_password_secret = '/change-password/:token',
	add_new_user = '/add-new-user',
	invitation = '/invitation/:id',
	authorized_user = '/:engId/',
	//integrations
	integrations_management = 'integrations',
	// users
	users_management = 'users',
	users_management_edit = 'users/:id',
	// subscriptions
	subscriptions_management = 'subscriptions',
	// billings
	billings_management = 'billings',
	// organizations
	organizations_management = 'organizations',
	organizations_management_edit = 'organizations/:id',
	// engagements
	engagements = 'engagements',
	engagements_archived = 'engagements/archived',
	// plans
	plan = 'plan',
	plan_add = 'plan/new-plan',
	plan_edit = 'plan/:planId/plan-document/:storyboardId?',
	plan_ai_build = 'plan/:planId/ai-build',
	plan_tags_management = 'plan/tags',
	plan_archived = 'plan/archived',
	//Workspaces
	workspaces = 'workspaces',
	workspaces_tags_management = 'workspaces/tags',
	workspaces_archived = 'workspaces/archived',
	workspace_build = 'workspaces/:workspaceId/build',
	workspace_ai_build = 'workspaces/:workspaceId/ai-build',
	workspace_edit = 'workspaces/:workspaceId/approach',
	//action_plan
	action_plan = 'action-plan',
	action_plan_archived = 'action-plan/archived',
	action_plan_edit = 'action-plan/:id',
	action_plan_tags_management = 'action-plan/tags',
	action_plan_categories_management = 'action-plan/categories',
	action_plan_manage_integrations = 'action-plan/manage-integrations',
	// project-portfolio - projects
	project_portfolio_projects = 'projects',
	project_portfolio_projects_storyboard_details = 'projects/portfolioGuidance/:storyboardId',
	project_portfolio_projects_edit = 'projects/:id',
	project_portfolio_projects_tags_management = 'projects/tags',
	project_portfolio_projects_categories_management = 'projects/categories',
	project_portfolio_projects_edit_tags_management = 'projects/:id/tags',
	project_portfolio_projects_edit_categories_management = 'projects/:id/categories',
	project_portfolio_projects_edit_storyboard_details = 'projects/:id/workProducts/:storyboardId',
	// optimization
	optimization = 'optimization',
	optimization_ai_build = 'optimization/:optId/ai-build',
	optimization_row_data = 'optimization/:optId/optimization-row-data/:storyboardId',
	optimization_ai_report = 'optimization/:optId/optimization-ai-report/:storyboardId?',
	optimization_cards = 'optimization/:optId',
	// retrospectives
	retrospectives = 'retrospectives',
	retrospectives_lessons_learned_add = 'retrospectives/new-lessons-learned',
	retrospectives_lessons_learned_edit = 'retrospectives/lessons-learned/:id',
	// actionPlan
	actionPlan_tags_management = 'actionPlan/tags',
	actionPlan_categorys_management = 'actionPlan/categories',
	// storyboards
	storyboards = 'storyboards',
	storyboard_details = 'storyboards/:storyboardId',
	storyboards_tags_management = 'storyboards/tags',
	storyboards_categories_management = 'storyboards/categories',
	// home
	home = 'home',
	// diagrams
	diagrams = 'diagrams',
	diagrams_edit = 'diagrams/:diagramId',
	diagrams_ai_build = 'diagrams/:diagramId/ai-build',
	// operating-model
	operating_model = 'operating-model',
	operating_model_archived = 'operating-model/archived',
	operating_model_ai_build = 'operating-model/:oppModelId/ai-build',
	operating_model_ai_report = 'operating-model/:oppModelId/operating-model-ai-report/:storyboardId?',
	// forecast
	forecast = 'forecast',
	forecast_details = 'forecast/:id',
	forecast_archived = 'forecast/archived',
	forecast_tags_management = 'forecast/tags',
	forecast_categories_management = 'forecast/categories',
	// file-exporter
	file_explorer = 'file-exporter',
	//IndustryReports
	industry_reports = 'industry-reports',
	industry_reports_details = 'industry-reports/:storyboardId',
	//ProcessReports
	process_reports = 'process-reports',
	process_reports_details = 'process-reports/:storyboardId',

	// public pages
	public = 'public',
	public_not_found = 'public/not-found',
	public_storyboard = 'public/storyboard/:hash',
	public_optimization_feedback = 'public/optimization-feedback/:hash',
}
