import {
	FunctionComponent,
	ReactElement,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

export type TOAuthContextReturnType = {
	authCode: string | null;
	resetAuthCode: () => void;
};

const OAuthContext = createContext<TOAuthContextReturnType>(undefined!);

export const OAuthContextProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	const [searchParams] = useSearchParams();
	const [authCode, setAuthCode] = useState<string | null>(null);

	const resetAuthCode = (): void => {
		setAuthCode(null);
	};

	useEffect(() => {
		const code = searchParams.get('code');

		if (code) {
			localStorage.setItem('code', code);
			window.close();
		}
	}, [searchParams]);

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent): void => {
			if (event.key === 'code') {
				const authCode = event.newValue;
				if (authCode) {
					setAuthCode(authCode);
					localStorage.removeItem('code');
				}
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (
		<OAuthContext.Provider value={{ authCode, resetAuthCode }}>{children}</OAuthContext.Provider>
	);
};

export default function OAuthConsumer(): TOAuthContextReturnType {
	const context = useContext(OAuthContext);

	if (context === undefined) {
		throw new Error('OAuthConsumer must be used within OAuthProvider');
	}
	return context;
}
