import get from 'lodash/get';
import set from 'lodash/set';
import { ADDONS_VALUES } from 'shared/api/billing.service';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import { TSubscriptionsPlans } from 'shared/types/subscriptionPlansTypes';

import { BasicModel } from './basic-model';

export interface PropertyResolver {
	property: string;
	path?: string;
	deserialize?: (this: any, data: any, values: any) => any;
}

const propertyResolvers: PropertyResolver[] = [
	{
		property: 'subscriptionPlans',
		path: 'subscriptionPlans',
	},
	{
		property: 'subscriptionStatus',
		path: 'subscriptionStatus',
	},
	{
		property: 'subscriptionAddons',
		path: 'subscriptionAddons',
	},
	{
		property: 'accessLevelTypesOriginal',
		path: 'accessLevelTypes',
	},
	{
		property: 'accessLevelTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.accessLevelTypes) {
				return model.transformDataToSelect(data.accessLevelTypes);
			}
		},
	},
	{
		property: 'subscriptionPlanTypesOriginal',
		path: 'subscriptionPlanTypes',
	},
	{
		property: 'subscriptionPlanTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.subscriptionPlanTypes) {
				return model.transformDataToSelect(data.subscriptionPlanTypes);
			}
		},
	},
	{
		property: 'diagramStatusTypesOriginal',
		path: 'diagramStatusTypes',
	},
	{
		property: 'diagramStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.diagramStatusTypes) {
				return model.transformDataToSelect(data.diagramStatusTypes);
			}
		},
	},
	{
		property: 'expenseTypesOriginal',
		path: 'expenseTypes',
	},
	{
		property: 'expenseTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.expenseTypes) {
				return model.transformDataToSelect(data.expenseTypes);
			}
		},
	},
	{
		property: 'budgetCategoryTypesOriginal',
		path: 'budgetCategoryTypes',
	},
	{
		property: 'budgetCategoryTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.budgetCategoryTypes) {
				return model.transformDataToSelect(data.budgetCategoryTypes);
			}
		},
	},
	{
		property: 'projectHealthTypesOriginal',
		path: 'projectHealthTypes',
	},
	{
		property: 'projectHealthTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.projectHealthTypes) {
				return model.transformDataToSelect(data.projectHealthTypes);
			}
		},
	},
	{
		property: 'projectStatusTypesOriginal',
		path: 'projectStatusTypes',
	},
	{
		property: 'projectStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.projectStatusTypes) {
				return model.transformDataToSelect(data.projectStatusTypes);
			}
		},
	},
	{
		property: 'raidCategoryTypesOriginal',
		path: 'raidCategoryTypes',
	},
	{
		property: 'raidCategoryTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.raidCategoryTypes) {
				return model.transformDataToSelect(data.raidCategoryTypes);
			}
		},
	},
	{
		property: 'learnedLessonTypesOriginal',
		path: 'learnedLessonTypes',
	},
	{
		property: 'learnedLessonTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.learnedLessonTypes) {
				return model.transformDataToSelect(data.learnedLessonTypes);
			}
		},
	},
	{
		property: 'businessUnitSubTypesOriginal',
		path: 'businessUnitSubTypes',
	},
	{
		property: 'businessUnitSubTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.businessUnitSubTypes) {
				return model.transformDataToSelect(data.businessUnitSubTypes);
			}
		},
	},
	{
		property: 'headquartersSubTypesOriginal',
		path: 'headquartersSubTypes',
	},
	{
		property: 'headquartersSubTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.headquartersSubTypes) {
				return model.transformDataToSelect(data.headquartersSubTypes);
			}
		},
	},
	{
		property: 'organizationTypesOriginal',
		path: 'organizationTypes',
	},
	{
		property: 'organizationTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.organizationTypes) {
				return model.transformDataToSelect(data.organizationTypes);
			}
		},
	},
	{
		property: 'userTypesOriginal',
		path: 'userTypes',
	},
	{
		property: 'userTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.userTypes) {
				return model.transformDataToSelect(data.userTypes);
			}
		},
	},
	{
		property: 'usaStateTypesOriginal',
		path: 'usaStateTypes',
	},
	{
		property: 'usaStateTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.usaStateTypes) {
				return model.transformDataToSelect(data.usaStateTypes);
			}
		},
	},
	{
		property: 'riskResponseTypesOriginal',
		path: 'riskResponseTypes',
	},
	{
		property: 'riskResponseTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.riskResponseTypes) {
				return model.transformDataToSelect(data.riskResponseTypes);
			}
		},
	},
	{
		property: 'resolutionStatusTypesOriginal',
		path: 'resolutionStatusTypes',
	},
	{
		property: 'resolutionStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.resolutionStatusTypes) {
				return model.transformDataToSelect(data.resolutionStatusTypes);
			}
		},
	},
	{
		property: 'resolutionTypesOriginal',
		path: 'resolutionTypes',
	},
	{
		property: 'resolutionTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.resolutionTypes) {
				return model.transformDataToSelect(data.resolutionTypes);
			}
		},
	},
	{
		property: 'riskLevelTypesOriginal',
		path: 'riskLevelTypes',
	},
	{
		property: 'riskLevelTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.riskLevelTypes) {
				return model.transformDataToSelect(data.riskLevelTypes);
			}
		},
	},
	{
		property: 'engagementStatusTypesOriginal',
		path: 'engagementStatusTypes',
	},
	{
		property: 'engagementStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.engagementStatusTypes) {
				return model.transformDataToSelect(data.engagementStatusTypes);
			}
		},
	},
	{
		property: 'accountStatusTypesOriginal',
		path: 'accountStatusTypes',
	},
	{
		property: 'accountStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.accountStatusTypes) {
				return model.transformDataToSelect(data.accountStatusTypes);
			}
		},
	},
	{
		property: 'lessonSolutionStatusTypesOriginal',
		path: 'lessonSolutionStatusTypes',
	},
	{
		property: 'lessonSolutionStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.lessonSolutionStatusTypes) {
				return model.transformDataToSelect(data.lessonSolutionStatusTypes);
			}
		},
	},
	{
		property: 'actionPlanPriorityTypesOriginal',
		path: 'actionPlanItemPriorityTypes',
	},
	{
		property: 'actionPlanItemPriorityTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.actionPlanItemPriorityTypes) {
				return model.transformDataToSelect(data.actionPlanItemPriorityTypes);
			}
		},
	},
	{
		property: 'actionPlanStatusTypesOriginal',
		path: 'actionPlanItemStatusTypes',
	},
	{
		property: 'actionPlanItemStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.actionPlanItemStatusTypes) {
				return model.transformDataToSelect(data.actionPlanItemStatusTypes);
			}
		},
	},
	{
		property: 'assignmentRoleTypesOriginal',
		path: 'assignmentRoleTypes',
	},
	{
		property: 'assignmentRoleTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.assignmentRoleTypes) {
				return model.transformDataToSelect(data.assignmentRoleTypes);
			}
		},
	},
	{
		property: 'storyBoardBlockTypesOriginal',
		path: 'storyBoardBlockTypes',
	},
	{
		property: 'storyBoardBlockTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.storyBoardBlockTypes) {
				return model.transformDataToSelect(data.storyBoardBlockTypes);
			}
		},
	},
	{
		property: 'companySizeTypesOriginal',
		path: 'companySizeTypes',
	},
	{
		property: 'companySizeTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.companySizeTypes) {
				return model.transformDataToSelect(data.companySizeTypes);
			}
		},
	},
	{
		property: 'modelTypesOriginal',
		path: 'modelTypes',
	},
	{
		property: 'modelTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.modelTypes) {
				return model.transformDataToSelect(data.modelTypes);
			}
		},
	},
	{
		property: 'businessFunctionTypesOriginal',
		path: 'businessFunctionTypes',
	},
	{
		property: 'businessFunctionTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.businessFunctionTypes) {
				return model.transformDataToSelect(data.businessFunctionTypes);
			}
		},
	},
	{
		property: 'forecastStatusTypesOriginal',
		path: 'forecastStatusTypes',
	},
	{
		property: 'forecastStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.forecastStatusTypes) {
				return model.transformDataToSelect(data.forecastStatusTypes);
			}
		},
	},
	{
		property: 'planningPeriodTypesOriginal',
		path: 'planningPeriodTypes',
	},
	{
		property: 'planningPeriodTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.planningPeriodTypes) {
				return model.transformDataToSelect(data.planningPeriodTypes);
			}
		},
	},
	{
		property: 'measureUnitTypesOriginal',
		path: 'measureUnitTypes',
	},
	{
		property: 'measureUnitTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.measureUnitTypes) {
				return model.transformDataToSelect(data.measureUnitTypes);
			}
		},
	},
	{
		property: 'productServiceTypesOriginal',
		path: 'productServiceTypes',
	},
	{
		property: 'productServiceTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.productServiceTypes) {
				return model.transformDataToSelect(data.productServiceTypes);
			}
		},
	},
	{
		property: 'initiativeSupportTypesOriginal',
		path: 'initiativeSupportTypes',
	},
	{
		property: 'initiativeSupportTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.initiativeSupportTypes) {
				return model.transformDataToSelect(data.initiativeSupportTypes);
			}
		},
	},
	{
		property: 'geographicAreaTypesOriginal',
		path: 'geographicAreaTypes',
	},
	{
		property: 'geographicAreaTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.geographicAreaTypes) {
				return model.transformDataToSelect(data.geographicAreaTypes);
			}
		},
	},
	{
		property: 'organizationSizeTypesOriginal',
		path: 'organizationSizeTypes',
	},
	{
		property: 'organizationSizeTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.organizationSizeTypes) {
				return model.transformDataToSelect(data.organizationSizeTypes);
			}
		},
	},
	{
		property: 'stageOfGrowthTypesOriginal',
		path: 'stageOfGrowthTypes',
	},
	{
		property: 'stageOfGrowthTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.stageOfGrowthTypes) {
				return model.transformDataToSelect(data.stageOfGrowthTypes);
			}
		},
	},
	{
		property: 'planReasonTypesOriginal',
		path: 'planReasonTypes',
	},
	{
		property: 'planReasonTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.planReasonTypes) {
				return model.transformDataToSelect(data.planReasonTypes);
			}
		},
	},
	{
		property: 'industryTypesOriginal',
		path: 'industryTypes',
	},
	{
		property: 'industryTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.industryTypes) {
				return model.transformDataToSelect(data.industryTypes);
			}
		},
	},
	{
		property: 'raidItemStatusTypesOriginal',
		path: 'raidItemStatusTypes',
	},
	{
		property: 'raidItemStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.raidItemStatusTypes) {
				return model.transformDataToSelect(data.raidItemStatusTypes);
			}
		},
	},
	{
		property: 'projectStatusManagementItemTypesOriginal',
		path: 'projectStatusManagementItemTypes',
	},
	{
		property: 'projectStatusManagementItemTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.projectStatusManagementItemTypes) {
				return model.transformDataToSelect(data.projectStatusManagementItemTypes);
			}
		},
	},
	{
		property: 'calendarEventFilterStatusTypesOriginal',
		path: 'calendarEventFilterStatusTypes',
	},
	{
		property: 'calendarEventFilterStatusTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.calendarEventFilterStatusTypes) {
				return model.transformDataToSelect(data.calendarEventFilterStatusTypes);
			}
		},
	},
	{
		property: 'actionItemGroupByTypesOriginal',
		path: 'actionItemGroupByTypes',
	},
	{
		property: 'actionItemGroupByTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.actionItemGroupByTypes) {
				return model.transformDataToSelect(data.actionItemGroupByTypes);
			}
		},
	},
	{
		property: 'levelOfDetailTypesOriginal',
		path: 'levelOfDetailTypes',
	},
	{
		property: 'levelOfDetailTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.levelOfDetailTypes) {
				return model.transformDataToSelect(data.levelOfDetailTypes);
			}
		},
	},
	{
		property: 'aiHashtagOptionTypesOriginal',
		path: 'aiHashtagOptionTypes',
	},
	{
		property: 'aiHashtagOptionTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.aiHashtagOptionTypes) {
				return model.transformDataToSelect(data.aiHashtagOptionTypes);
			}
		},
	},
	{
		property: 'applicationModuleTypesOriginal',
		path: 'applicationModuleTypes',
	},
	{
		property: 'applicationModuleTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.applicationModuleTypes) {
				return model.transformDataToSelect(data.applicationModuleTypes);
			}
		},
	},
	{
		property: 'manualChartTypesOriginal',
		path: 'manualChartTypes',
	},
	{
		property: 'manualChartTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.manualChartTypes) {
				return model.transformDataToSelect(data.manualChartTypes);
			}
		},
	},
	{
		property: 'processForImprovementTypesOriginal',
		path: 'processForImprovementTypes',
	},
	{
		property: 'processForImprovementTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.processForImprovementTypes) {
				return model.transformDataToSelect(data.processForImprovementTypes);
			}
		},
	},
	{
		property: 'dueDateFilterTypesOriginal',
		path: 'dueDateFilterTypes',
	},
	{
		property: 'dueDateFilterTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.dueDateFilterTypes) {
				return model.transformDataToSelect(data.dueDateFilterTypes);
			}
		},
	},
	{
		property: 'operatingModelTypesOriginal',
		path: 'operatingModelTypes',
	},
	{
		property: 'operatingModelTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.operatingModelTypes) {
				return model.transformDataToSelect(data.operatingModelTypes);
			}
		},
	},
	{
		property: 'diagramTypesOriginal',
		path: 'diagramTypes',
	},
	{
		property: 'diagramTypes',
		deserialize: (
			model: InitializationPropertiesModel,
			data,
		): TSelectItem<string>[] | undefined => {
			if (data?.diagramTypes) {
				return model.transformDataToSelect(data.diagramTypes);
			}
		},
	},
];

export type TSelectItem<T> = { value: T; label: string; key?: string };
type TDynamicObj = { [key: string]: string };
type RiskAnalysisStatus = { [key: string]: { [key: string]: number } };

class InitializationPropertiesModel implements BasicModel {
	subscriptionAddons: (keyof typeof ADDONS_VALUES)[];
	subscriptionStatus: keyof typeof SUBSCRIPTION_STATUSES;
	accessLevelTypes: TSelectItem<string>[];
	accessLevelTypesOriginal: TDynamicObj;
	diagramStatusTypes: TSelectItem<string>[];
	diagramStatusTypesOriginal: TDynamicObj;
	budgetCategoryTypes: TSelectItem<string>[];
	budgetCategoryTypesOriginal: TDynamicObj;
	expenseTypes: TSelectItem<string>[];
	expenseTypesOriginal: TDynamicObj;
	subscriptionPlanTypes: TSelectItem<string>[];
	subscriptionPlanTypesOriginal: TDynamicObj;
	businessUnitSubTypes: TSelectItem<string>[];
	businessUnitSubTypesOriginal: TDynamicObj;
	functionalAreaTypes: TSelectItem<string>[];
	functionalAreasTypesOriginal: TDynamicObj;
	headquartersSubTypes: TSelectItem<string>[];
	headquartersSubTypesOriginal: TDynamicObj;
	projectHealthTypesOriginal: TDynamicObj;
	projectHealthTypes: TSelectItem<string>[];
	projectStatusTypesOriginal: TDynamicObj;
	projectStatusTypes: TSelectItem<string>[];
	raidCategoryTypesOriginal: TDynamicObj;
	raidCategoryTypes: TSelectItem<string>[];
	learnedLessonTypesOriginal: TDynamicObj;
	learnedLessonTypes: TSelectItem<string>[];
	organizationTypes: TSelectItem<string>[];
	organizationTypesOriginal: TDynamicObj;
	userTypes: TSelectItem<string>[];
	userTypesOriginal: TDynamicObj;
	usaStateTypes: TSelectItem<string>[];
	usaStateTypesOriginal: TDynamicObj;
	riskLevelTypes: TSelectItem<string>[];
	riskLevelTypesOriginal: TDynamicObj;
	resolutionStatusTypes: TSelectItem<string>[];
	resolutionStatusTypesOriginal: TDynamicObj;
	resolutionTypes: TSelectItem<string>[];
	resolutionTypesOriginal: TDynamicObj;
	riskResponseTypes: TSelectItem<string>[];
	riskResponseTypesOriginal: TDynamicObj;
	accountStatusTypes: TSelectItem<string>[];
	engagementStatusTypes: TSelectItem<string>[];
	engagementStatusTypesOriginal: TDynamicObj;
	accountStatusTypesOriginal: TDynamicObj;
	subscriptionPlans: TSubscriptionsPlans;
	lessonSolutionStatusTypes: TSelectItem<string>[];
	lessonSolutionStatusTypesOriginal: RiskAnalysisStatus;
	actionPlanItemPriorityTypes: TSelectItem<string>[];
	actionPlanPriorityTypesOriginal: TDynamicObj;
	actionPlanItemStatusTypes: TSelectItem<string>[];
	actionPlanStatusTypesOriginal: TDynamicObj;
	assignmentRoleTypes: TSelectItem<string>[];
	assignmentRoleTypesOriginal: TDynamicObj;
	storyBoardBlockTypes: TSelectItem<string>[];
	storyBoardBlockTypesOriginal: TDynamicObj;
	companySizeTypes: TSelectItem<string>[];
	companySizeTypesOriginal: TDynamicObj;
	modelTypes: TSelectItem<string>[];
	modelTypesOriginal: TDynamicObj;
	businessFunctionTypes: TSelectItem<string>[];
	businessFunctionTypesOriginal: TDynamicObj;
	forecastStatusTypes: TSelectItem<string>[];
	forecastStatusTypesOriginal: TDynamicObj;
	planningPeriodTypes: TSelectItem<string>[];
	planningPeriodTypesOriginal: TDynamicObj;
	geographicAreaTypes: TSelectItem<string>[];
	geographicAreaTypesOriginal: TDynamicObj;
	organizationSizeTypes: TSelectItem<string>[];
	organizationSizeTypesOriginal: TDynamicObj;
	planReasonTypes: TSelectItem<string>[];
	planTypesOriginal: TDynamicObj;
	stageOfGrowthTypes: TSelectItem<string>[];
	stageOfGrowthTypesOriginal: TDynamicObj;
	measureUnitTypes: TSelectItem<string>[];
	measureUnitTypesOriginal: TDynamicObj;
	productServiceTypes: TSelectItem<string>[];
	initiativeSupportTypesOriginal: TDynamicObj;
	initiativeSupportTypes: TSelectItem<string>[];
	productServiceTypesOriginal: TDynamicObj;
	industryTypes: TSelectItem<string>[];
	industryTypesOriginal: TDynamicObj;
	raidItemStatusTypes: TSelectItem<string>[];
	raidItemStatusTypesOriginal: TDynamicObj;
	projectStatusManagementItemTypes: TSelectItem<string>[];
	projectStatusManagementItemTypesOriginal: TDynamicObj;
	calendarEventFilterStatusTypes: TSelectItem<string>[];
	calendarEventFilterStatusTypesOriginal: TDynamicObj;
	actionItemGroupByTypes: TSelectItem<string>[];
	actionItemGroupByTypesOriginal: TDynamicObj;
	levelOfDetailTypes: TSelectItem<string>[];
	levelOfDetailTypesOriginal: TDynamicObj;
	aiHashtagOptionTypes: TSelectItem<string>[];
	aiHashtagOptionTypesOriginal: TDynamicObj;
	applicationModuleTypes: TSelectItem<string>[];
	applicationModuleTypesOriginal: TDynamicObj;
	manualChartTypes: TSelectItem<string>[];
	manualChartTypesOriginal: TDynamicObj;
	processForImprovementTypes: TSelectItem<string>[];
	processForImprovementTypesOriginal: TDynamicObj;
	dueDateFilterTypes: TSelectItem<string>[];
	dueDateFilterTypesOriginal: TDynamicObj;
	operatingModelTypes: TSelectItem<string>[];
	operatingModelTypesOriginal: TDynamicObj;
	diagramTypes: TSelectItem<string>[];
	diagramTypesOriginal: TDynamicObj;

	constructor(values: any) {
		if (values) {
			this.deserialize(values);
		}
	}

	transformDataToSelect(data): { label: string; value: string }[] {
		return Object.entries(data).map(([value, label]) => ({ value, label })) as {
			label: string;
			value: string;
		}[];
	}

	transformObjectToSelect(data: {
		[key: string]: { [key: string]: string };
	}): { label: string; key: string; value: string }[] {
		return Object.entries(data).map(([key, obj]) => {
			const [label, value] = Object.entries(obj)[0];
			return { key, value, label };
		}) as {
			label: string;
			key: string;
			value: string;
		}[];
	}

	deserialize(values: any): void {
		propertyResolvers.forEach(({ property, deserialize, path }) => {
			if (typeof deserialize === 'function') {
				set(this, property, deserialize(this, values));
			} else if (typeof path !== 'undefined') {
				set(this, property, get(values, path));
			}
		});
	}
}

export default InitializationPropertiesModel;
