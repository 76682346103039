import { FunctionComponent, ReactElement, createContext, useContext } from 'react';

export type TConfluenceOauthContextReturnType = {
	handleConfluenceLogin: () => void;
};

const ConfluenceOauthContext = createContext<TConfluenceOauthContextReturnType>(undefined!);

export const ConfluenceOauthProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	const handleConfluenceLogin = (): void => {
		const clientId = (): string => process.env.REACT_APP_CONFLUENCE_CLIENT_ID || '';
		const redirectUri = (): string =>
			encodeURIComponent(process.env.REACT_APP_CONFLUENCE_REDIRECT_URL || '');
		const scope = encodeURIComponent(
			'offline_access read:jira-work read:jira-user write:jira-work manage:jira-webhook read:workflow:jira',
		);

		const authUrl = `https://auth.atlassian.com/authorize?client_id=${clientId()}&scope=${scope}&redirect_uri=${redirectUri()}&response_type=code&prompt=consent`;

		window.open(authUrl);
	};

	return (
		<ConfluenceOauthContext.Provider value={{ handleConfluenceLogin }}>
			{children}
		</ConfluenceOauthContext.Provider>
	);
};

export default function ConfluenceOauthConsumer(): TConfluenceOauthContextReturnType {
	const context = useContext(ConfluenceOauthContext);

	if (context === undefined) {
		throw new Error('ConfluenceOauthConsumer must be used within a ConfluenceOauthProvider');
	}
	return context;
}
