import { FC } from 'react';

import { Button, Flex } from 'antd';
import { ReactComponent as SyncIcon } from 'assets/images/svg/sync-icon.svg';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { concatUrl } from 'routers';
import AuthConsumer from 'shared/guards/auth.guard';
import { useGetNavigatePathDueToPlan } from 'shared/hooks/useGetNavigatePathDueToPlan';

import styles from './ErrorPage.module.scss';

const ErrorPage: FC<FallbackProps> = ({ error, resetErrorBoundary }): JSX.Element => {
	const { authed } = AuthConsumer();
	const { engId = '' } = useParams();
	const navigate = useNavigate();

	const { navigatePath } = useGetNavigatePathDueToPlan();

	const handleReloadPage = (): void => {
		resetErrorBoundary();
		window.location.reload();
	};

	const handleNavigateToHome = (): void => {
		navigate(concatUrl([engId, navigatePath], true), {
			replace: true,
		});
	};

	const stringError = typeof error === 'string' ? error : null;
	const isChunkError = /Loading chunk [\d]+ failed/.test(
		error?.response?.data?.message || error?.message || stringError,
	);

	if (isChunkError) {
		return (
			<Flex className={styles.container} justify="center" align="center">
				<Flex
					vertical
					gap={24}
					justify="center"
					align="center"
					className={styles.chunkErrorContainer}
				>
					<h1 className={styles.title}>New Version</h1>
					<p className={styles.text}>
						A new version of the application is now available. Please refresh the page to update and
						enjoy the latest features.
					</p>
					<SyncIcon />
					<Button type="primary" onClick={handleReloadPage} style={{ width: '100%' }}>
						Refresh
					</Button>
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex className={styles.container} justify="center" align="center">
			<Flex vertical gap={24} align="center">
				<h1>{error?.response?.data?.message || error?.message || stringError || 'Error!'}</h1>
				<Flex gap={16}>
					<Button type="primary" onClick={handleReloadPage}>
						Reload Page
					</Button>
					{authed && engId && (
						<Button type="primary" onClick={handleNavigateToHome}>
							Go Home Page
						</Button>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ErrorPage;
